<template>
  <div class="grid grid-cols-12 xl:mx-60 lg:mx-24 gap-10 p-5 content-center">
    <div class="col-span-12 text-center mt-10">
      <h1 class="font-bold text-5xl opacity-50">Templates</h1>
    </div>
    <div
      v-for="template in stripoTemplates.slice(1)"
      :key="template.id"
      @click="selectTemplate(template.id)"
      class="col-span-12 md:col-span-4 pointer email-template mb-10"
    >
      <div
        class="h-96 relative overflow-hidden rounded-md border"
      >
        <img
          :src="imageUrl(template.imageName)"
          class="absolute"
          style="
            top: 0;
            transition: all 0.5s linear;
            width: 136%;
            max-width: 136% !important;
            left: -18%;
          "
        />
      </div>
      <p class="font-bold mt-3 text-lg opacity-70">
        {{ template.description }}
      </p>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import StripoTemplates from "../../../../../helpers/stripo/stripoTemplates";

export default {
  name: "AppStripoTemplates",
  emits: ["selectTemplate"],
  // eslint-disable-next-line
  data: function() {
    return {
      stripoTemplates: StripoTemplates,
    };
  },
  methods: {
    imageUrl(imageName) {
      // eslint-disable-next-line
      return require(`@/assets/img/templates/${imageName}`);
    },
    selectTemplate(templateId) {
      if (
        window.confirm(
          "This will overwrite your current email. Are you sure you want to continue?"
        )
      ) {
        this.$emit("selectTemplate", StripoTemplates[templateId]);
      }
    },
  },
  mounted() {
    /* eslint-disable */
    $(document).on("mouseenter", ".email-template", function() {
      const imageHeight = $(this)
        .find("img")
        .height();
      $(this)
        .find("img")
        .css("transition-duration", "7s")
        .css("top", "-" + (imageHeight - $(this).height()) + "px");
    });
    $(document).on("mouseleave", ".email-template", function() {
      const imageHeight = $(this)
        .find("img")
        .height();
      $(this)
        .find("img")
        .css("transition-duration", "2s")
        .css("top", "0px");
    });
    /* eslint-enable no-return-assign, no-param-reassign */
  }
};
</script>
